import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, mergeMap, tap, map } from 'rxjs/operators';
import { Observable, ObservableInput, of, from, BehaviorSubject } from 'rxjs';
import { AnyARecord, resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class ServiceBusService {
  private fullName = new BehaviorSubject<string>('');

  private http: HttpClient;
  private esbEndpoint: string;

  constructor(private _http: HttpClient) {
    this.http = _http;
    this.esbEndpoint = environment.esb_enpoint;
  }

  public getFullName(): Observable<string> {
    this.fullName.next(localStorage.getItem('fullName'));
    return this.fullName.asObservable();
  }

  public setFullName(fullName: string): void {
    this.fullName.next(fullName);
  }

  public getManagerInfo(): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Accept', 'application/json');

    const _data = this.http.get(
      this.esbEndpoint).pipe(
          map(log => log),
          tap(_ => this.log('Fetching employee data')),
          catchError(this.handleError('loadManagers', [])),
    );

    return _data;
    /*return from(
      fetch(
        this.esbEndpoint,
        {
          body: JSON.stringify({}),
          headers: {
            'Accept': 'application/json',
          },
          method: 'GET',
          mode: 'no-cors'
        }
      )
    ).pipe(
      tap((data) => {
        if (!!data['staff'] && data['staff']['employee'] instanceof Array) {
          const arrEmployees = data['staff']['employee'];
          localStorage.setItem('managers', JSON.stringify(arrEmployees));
        }
      }),
      catchError(this.handleError('loadManagers', []))
    );*/
  }

  /*public getManagerInfo(): Observable<any> {
    return this.http.get(this.esbEndpoint).pipe(
      tap((data) => {
        if (!!data['staff'] && data['staff']['employee'] instanceof Array) {
          const arrEmployees = data['staff']['employee'];
          localStorage.setItem('managers', JSON.stringify(arrEmployees));
        }
      }),
      catchError(this.handleError('loadManagers', []))
    );
  }

  /*private loadManagerInfo(){
    return new Promise<void>(resolve => {
      this.http.get(this.esbEndpoint).pipe(
        tap((data) => {
          if (!!data['staff'] && data['staff']['employee'] instanceof Array) {
            const arrEmployees = data['staff']['employee'];
            localStorage.setItem('managers', JSON.stringify(arrEmployees));
            resolve();
          }
        })
    );
  }*/

  public findEmployeeSigned(employeeEmail: string): boolean {
    let result = false;
    const employees = JSON.parse(localStorage.getItem('managers'));
    if (!!employees) {
      let _employeeMail = employeeEmail;
      if(employeeEmail && employeeEmail.includes('nearshoretechnology')){
        _employeeMail = employeeEmail.replace('nearshoretechnology', 'blueriver');
      }
      const employeeFound = employees.filter( i => i.email === _employeeMail);
      if (!!employeeFound) {
        localStorage.setItem('employeeSigned', JSON.stringify(employeeFound));
        localStorage.setItem('fullName', employeeFound[0].fname + ' ' + employeeFound[0].lname);
        this.setFullName(employeeFound[0].fname + ' ' + employeeFound[0].lname)
        result = true;
      }
    }
    return result;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    /*if(typeof result === 'function'){
      if(localStorage.getItem('noVpn') === null)
        localStorage.setItem('noVpn', '1');
    }*/
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  protected log(message: string) {
    console.log(message);
  }
}
